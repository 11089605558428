<template>
  <div class="page-main">
    <SystemTabs />
    <div class="page-content">
      <vxe-grid
        ref="refTable"
        size="mini"
        border
        highlight-hover-row
        :loading="loading"
        :height="this.$util.getViewHeight() - 150"
        :export-config="{ type: ['xlsx'], types: ['xlsx'] }"
        :data="tableDataMain"
        :columns="tableColumn"
      >
        <template #default_bumen="{ row }">
          {{ row.bu_men_name.join('>') }}
        </template>
        <template #default_created="{ row }">
          {{ row.created.slice(0, 10) }}
        </template>
        <template #default_updated="{ row }">
          {{ row.updated.slice(0, 10) }}
        </template>
        <template #default_state="{ row }">
          <el-switch
            v-if="!row.is_boss"
            :active-value="1"
            :inactive-value="0"
            v-model="row.state"
            @change="fileSwitch(row, $event)"
          />
        </template>

        <template #default_operate="{ row }">
          <el-link type="primary" @click="fileEdit(row)">编辑</el-link>
          <el-link class="m-l-5" type="info" @click="fileDelete(row)">删除</el-link>
        </template>
        <template #pager>
          <div></div>
          <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
        </template>
      </vxe-grid>
    </div>
    <b-popup-account
      ref="refPopupAccount"
      v-if="isShowPopupAccount"
      :show.sync="isShowPopupAccount"
      :detailId="activeFileId"
      @success="updatePageData"
    />
  </div>
</template>

<script>
import SystemTabs from '../common/system-tabs'

export default {
  components: {
    SystemTabs
  },
  data() {
    return {
      loading: true,
      tableColumn: [
        { title: 'ID', field: 'id', width: 40 },
        { title: '姓名', field: 'nick_name' },
        { title: '登录账号', field: 'user_name' },
        { title: '部门', field: 'bu_men_name', slots: { default: 'default_bumen' } },
        { title: '岗位权限(旧)', field: 'role_name' },
        { title: '创建日期', field: 'created', slots: { default: 'default_created' } },
        { title: '更新日期', field: 'updated', slots: { default: 'default_updated' } },
        {
          field: 'state',
          title: '启用',
          width: 80,
          slots: {
            default: 'default_state'
          }
        },
        { title: '操作', width: 320, slots: { default: 'default_operate' } }
      ],
      tableDataMain: [],
      pageOptions: {
        ...this.$constant.page,
        pageSize: 100
      },
      activeFileId: '',
      isShowPopupAccount: false
    }
  },
  mounted() {
    this.getListData()
    this.$nextTick(() => {
      this.$refs.refTable.connect(this.$refs.refToolbar) // 手动将表格和工具栏进行关联
    })
  },
  methods: {
    // 更新页面数据
    updatePageData() {
      this.getListData()
    },
    // 搜索列表数据
    searchListData() {
      this.pageOptions.currentPage = 1
      this.getListData()
    },
    // 获取列表数据
    getListData() {
      this.loading = true
      this.$api({
        method: 'post',
        url: '/admin/account/getAccountList',
        data: {
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize,
          filterData: {
            bu_men_id: 13 //13 游客
          }
        }
      })
        .then((res) => {
          if (res.status === 200) {
            this.tableDataMain = res.data.page.list
            this.pageOptions.pageSize = res.data.page.pageSize
            this.pageOptions.total = res.data.page.totalRow
            this.pageOptions.currentPage = res.data.page.pageNumber
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
    // 游客-分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    },
    // 游客-启用/禁用
    fileSwitch(row, $event) {
      row.state = row.state == 0 ? 1 : 0
      this.$confirm('确认要改变游客状态 : ' + row.nick_name + '?', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 发送ajax请求，处理
          this.$api({
            method: 'post',
            url: '/admin/account/switchStateVue',
            params: {
              id: row.id,
              checked: $event == 1 ? true : false
            }
          }).then((res) => {
            if (res.data.state === 'ok') {
              this.$message.success('编辑成功')
              row.state = row.state == 0 ? 1 : 0
            } else {
              this.$message.error(res.data.msg)
            }
          })
        })
        .catch(() => {
          this.$message.info('已取消操作')
        })
    },
    // 游客-编辑
    fileEdit(row) {
      this.activeFileId = row.id
      this.isShowPopupAccount = true
      this.$nextTick(() => {
        this.$refs.refPopupAccount.isTourist = true
      })
    },
    // 游客-删除
    fileDelete(row) {
      this.$confirm(`您确认要删除该游客(${row.nick_name})?`, '提示')
        .then(() => {
          this.$api({
            method: 'post',
            url: '/admin/account/deleteVue',
            params: {
              id: row.id
            }
          }).then((res) => {
            if (res.status === 200 && res.data.state === 'ok') {
              this.$message.success('删除成功')
              this.getListData()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
  display: flex;
  flex-direction: column;
  .page-content {
    height: 100%;
    position: relative;
    .page-left {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 318px;
      border: 1px solid #e4e7ed;
      border-radius: 4px;
    }
  }
}
</style>
